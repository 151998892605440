import { eq } from 'fp-ts'
import { Img, ResponsiveImage } from 'common/types'

type Eq<A> = eq.Eq<A>

export type ImgData = ResponsiveImage & {
  alt: string
}

const eqImgData: Eq<ImgData> = ResponsiveImage.eq

const fromCroppedImg = (alt: string) => (img: ResponsiveImage): ImgData => ({
  alt,
  thumb: img.thumb,
  origin: img.origin,
})

const selectSource = (data: { img: ImgData, webpSupported: boolean, thumb: boolean }): string => {
  const srcProp: keyof Img['src'] = data.webpSupported ? 'webp' : 'jpg'
  const imgProp: keyof ResponsiveImage = data.thumb ? 'thumb' : 'origin'
  return data.img[imgProp].src[srcProp]
}

export const ImgData = {
  eq: eqImgData,
  fromCroppedImg,
  selectSource,
}
