import * as D from 'io-ts/Decoder'
import { array, eq } from 'fp-ts'
import { pipe } from 'fp-ts/function'

export const NameWithExtension = pipe(
  D.string,
  D.parse(str => {
    const split = str.split('.')
    if (split.length !== 2) {
      return D.failure(str, 'NameWithExtension')
    } else {
      return D.success({
        full: str,
        base: split[0],
        extension: split[1],
      })
    }
  })
)

export type NameWithExtension = D.TypeOf<typeof NameWithExtension>

export const MultiSrcImg = D.struct({
  id: D.string,
  src: D.struct({
    jpg: D.string,
    webp: D.string,
  })
})

export type Img = D.TypeOf<typeof MultiSrcImg>

export const eqImg =
  eq.fromEquals<Img>((a, b) => a.src.jpg === b.src.jpg && a.src.webp === b.src.webp)

export const ResponsiveImageDecoder = D.struct({
  origin: MultiSrcImg,
  thumb: MultiSrcImg,
})

export type ResponsiveImage = D.TypeOf<typeof ResponsiveImageDecoder>

export const ResponsiveImage = {
  decoder: ResponsiveImageDecoder,
  eq: eq.struct<ResponsiveImage>({
    origin: eqImg,
    thumb: eqImg,
  }),
}

export const nonEmptyArray = <A> (item: D.Decoder<unknown, A>) => pipe(
  D.array(item),
  D.parse(arr => array.isNonEmpty(arr) ? D.success(arr) : D.failure(arr, 'NonEmptyArray<A>')),
)
