import { GalleryJson } from 'common/Gallery'
import { ProductJson } from 'common/Product'
import info from '../data/info.json'
import gallery from '../data/gallery.json'
import products from '../data/products.json'

export interface InfoJson {
  displayYear: number
  catalog: Array<string>
}

export const infoJson: InfoJson = info
export const galleryJson: GalleryJson = gallery as unknown as GalleryJson
export const productsJson: ProductJson = products as unknown as ProductJson
