import { option, nonEmptyArray } from 'fp-ts'
import { Route } from 'common/Route'
import { toS3BucketUrl } from 'const'
import { Product } from 'lib/Store'
type Option<A> = option.Option<A>

export type MetaData = {
  title: string
  description: string
  keywords: string
  social: Option<{
    url: string
    imageSrc: string
    imageAlt: string
  }>
}

const title = (str: string): string =>
  `${str} | LikaCvetok.by`

const metadataDefaults = {
  description: 'Купить тюльпаны к 8 марта от производителя. Высококачественный тюльпан из голландской луковицы, низкие цены, широкий ассортимент. ОПТ и мелкий ОПТ.',
  social: {
    imageSrc: toS3BucketUrl('/assets/tulips-1.jpg'),
    imageAlt: 'Букет тюльпанов Фабио',
  },
  keywords: [
    'LikaCvetok',
    'ЛикаЦветок',
    'тюльпаны к 8 марта',
    'тюльпаны недорого',
    'тюльпаны с доставкой',
    'тюльпаны от производителя',
  ].join(',')
}

const make =
  (f: (a: typeof metadataDefaults) => Partial<MetaData> & { title: string }): MetaData => ({
    description: metadataDefaults.description,
    keywords: metadataDefaults.keywords,
    social: option.none,
    ...f(metadataDefaults),
  })

const homePage = make(defaults => ({
  title: 'LikaCvetok.by - Тюльпаны оптом от производителя по лучшим ценам!',
  social: option.some({
    url: Route.home.url,
    ...defaults.social,
  }),
}))

const contactPage = make(defaults => ({
  title: title('Контакт'),
  social: option.some({
    url: Route.contact.url,
    ...defaults.social,
  }),
}))

const galleryPage: MetaData = make(defaults => ({
  title: title('Фотогалерея'),
  social: option.some({
    url: Route.gallery.url,
    ...defaults.social,
  }),
}))

const catalogPage: MetaData = make(defaults => ({
  title: title('Каталог'),
  social: option.some({
    url: Route.gallery.url,
    ...defaults.social,
  }),
}))

const productPage =
  (product: Product): MetaData => make(_defaults => ({
    title: title(product.name.full),
    social: option.some({
      url: Route.product.url(product.id),
      imageSrc: toS3BucketUrl(nonEmptyArray.head(product.images).origin.src.jpg),
      imageAlt: product.name.full,
    })
  }))

const notFoundPage = make(_defaults => ({
  title: title('Страница не найдена'),
}))

export const metaData = {
  homePage,
  catalogPage,
  contactPage,
  galleryPage,
  productPage,
  notFoundPage,
}
