import { forwardRef } from 'react'
import { Fab as MuiFab, styled } from '@mui/material'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

const Fab = styled(MuiFab)(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.speedDial,
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  },
  [theme.breakpoints.up('lg')]: {
    bottom: theme.spacing(6),
    right: theme.spacing(6)
  }
}))


export const CallUsFab =
  forwardRef<HTMLButtonElement, {}>((props, ref) => (
    <Fab ref={ref} variant="extended" aria-label="contact us" color="secondary" href="tel:+375292874787" {...props}>
      <LocalPhoneIcon sx={{ mr: 1 }} />
      Позвонить
    </Fab>
  ))
