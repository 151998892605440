import { FC } from 'react'
import { Typography, TypographyProps, styled } from '@mui/material'
import * as G from 'io-ts/Guard'
import * as css from 'lib/CSS'
import { mkShouldForwardProps } from 'lib/utils'
import { titleFont } from 'theme'

type Props = {
  tone?: 'light' | 'normal' | 'dark' | 'inherit'
  fontSize: 'inherit' | number | Responsive<number>
  letterSpacing?: number
  uppercase?: boolean

  className?: string
  component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'
  align?: TypographyProps['align']
  fontWeight?: TypographyProps['fontWeight']
  noWrap?: boolean
  gutterBottom?: boolean
}

type Responsive<A> = {
  xs: A
  sm?: A
  md?: A
  lg?: A
  xl?: A
}

const calcFontSizes = (fontSizeProp: Props['fontSize']): Responsive<string> => {
  if (fontSizeProp === 'inherit') {
    return { xs: fontSizeProp }
  } else if (G.number.is(fontSizeProp)) {
    return { xs: css.rem(fontSizeProp) }
  } else {
    return {
      xs: css.rem(fontSizeProp.xs),
      sm: fontSizeProp.sm ? css.rem(fontSizeProp.sm) : undefined,
      md: fontSizeProp.md ? css.rem(fontSizeProp.md) : undefined,
      lg: fontSizeProp.lg ? css.rem(fontSizeProp.lg) : undefined,
      xl: fontSizeProp.xl ? css.rem(fontSizeProp.xl) : undefined,
    }
  }
}

const toneAsColor = (tone: NonNullable<Props['tone']>): string => {
  switch (tone) {
    case 'light':
      return css.gray.light
    case 'normal':
      return css.gray.main
    case 'dark':
      return css.gray.dark
    case 'inherit':
      return tone
  }
}

type STypographyProps = Pick<Props, 'tone' | 'fontSize' | 'letterSpacing' | 'uppercase'>

const STypography = styled(Typography, {
  shouldForwardProp: mkShouldForwardProps<STypographyProps>(['tone', 'fontSize', 'letterSpacing', 'uppercase']),
})<STypographyProps>(({ theme, fontSize, letterSpacing, tone, uppercase }) => {
  const fontSizes = calcFontSizes(fontSize)
  return {
    fontFamily: titleFont,
    letterSpacing: css.rem(letterSpacing || 0.015),
    textTransform: uppercase ? 'uppercase' : undefined,
    color: toneAsColor(tone || 'normal'),
    fontSize: fontSizes.xs,
    [theme.breakpoints.up('sm')]: { fontSize: fontSizes.sm },
    [theme.breakpoints.up('md')]: { fontSize: fontSizes.md },
    [theme.breakpoints.up('lg')]: { fontSize: fontSizes.lg },
    [theme.breakpoints.up('xl')]: { fontSize: fontSizes.xl },
  }
})

export const Title: FC<Props> =
  props => <STypography variant="inherit" {...props} />
