import { FC, memo } from 'react'
import { Box, Container, Grid, styled } from '@mui/material'
import { Contact } from 'lib/Contact'
import { metaData } from 'lib/MetaData'
import { Banner, Contact as ContactItem, Header, Main, Meta, Paragraph, Section, Title, LazyImage } from 'components'

const SGrid = styled(Grid)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))

const SLazyImage = styled(LazyImage)({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
})

export const ContactContainer: FC = memo(() => {
  return (
    <>
      <Meta metaData={metaData.contactPage} />

      <Banner title="Контакт" imageSrc="/assets/contact-banner.jpg">
        <Header />
      </Banner>

      <Main>
        <Section>
          <Container maxWidth="lg" fixed>
            <Grid container spacing={4}>
              <Grid item xs={12} md={7}>
                <Box mb={3}>
                  <Title component="h3" fontSize={18/16}>
                    Успейте заказать
                  </Title>
                </Box>

                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Paragraph>
                      Для оформления заказа, получения бесплатной консультации или
                      рекомендации с выбором сортов свяжитесь с нами любым удобным
                      для Вас способом. Звоните в любое время!
                    </Paragraph>
                  </Grid>

                  {Contact.list.map(contact => (
                    <Grid key={contact.label} item xs={12}>
                      <ContactItem contact={contact} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <SGrid item xs={5}>
                <SLazyImage
                  src="/assets/tulips-1.jpg"
                  alt="Тюльпаны Фабио (Fabio)"
                />
              </SGrid>
            </Grid>
          </Container>
        </Section>
      </Main>
    </>
  )
})
