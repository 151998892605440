import { array as A, nonEmptyArray as NEA, option as O, record as R, string as STR } from 'fp-ts'
import { identity, pipe } from 'fp-ts/function'
import { Product as ProductDto, productListFromJson, ProductName } from 'common/Product'
import { ResponsiveImage } from 'common/types'
import { randomNItems } from 'lib/utils'
import { infoJson, galleryJson, productsJson } from './JsonData'
import { ImgData } from './types'
type NonEmptyArray<A> = NEA.NonEmptyArray<A>

export type Product = Omit<ProductDto, 'image'> & {
  name: ProductName
  images: NonEmptyArray<ImgData>
}

const galleryById = pipe(
  galleryJson,
  R.foldMap(STR.Ord)(R.getMonoid(NEA.getSemigroup<ResponsiveImage>()))(identity),
)

const allProductsList = pipe(
  productsJson,
  productListFromJson,
  A.map((product): Product => {
    const galleryImages = pipe(
      R.lookup(product.id, galleryById),
      O.getOrElse((): Array<ResponsiveImage> => []),
    )

    return {
      ...product,
      images: pipe(
        NEA.of(product.image),
        NEA.concat(product.images),
        NEA.concat(galleryImages),
        NEA.map(ImgData.fromCroppedImg(product.name.full)),
      ),
    }
  })
)

const allProductsMap = new Map(allProductsList.map(p => [p.id, p]))

function getById(id: string): O.Option<Product> {
  return O.fromNullable(allProductsMap.get(id))
}

const galleryByYear = pipe(
  galleryJson,
  R.foldMapWithIndex(STR.Ord)(A.getMonoid<{ year: string, images: Array<ImgData> }>())(
    (year, gallery) => A.of({
      year,
      images: pipe(
        gallery,
        R.foldMapWithIndex(STR.Ord)(A.getMonoid<ImgData>())(
          (id, data) => {
            const fullName = pipe(getById(id), O.match(() => 'Тюльпан', a => a.name.full))
            return pipe(
              data,
              A.map(ImgData.fromCroppedImg(fullName)),
            )
          },
        ),
        A.uniq(ImgData.eq)
      ),
    }),
  ),
)

const catalogProducts = pipe(
  infoJson.catalog,
  A.filterMap(getById)
)

const state = {
  products: {
    list: allProductsList,
    map: allProductsMap,
    catalogList: catalogProducts
  },
  gallery: {
    byYear: galleryByYear,
    images: pipe(
      galleryByYear,
      A.foldMap(A.getMonoid<ImgData>())(a => a.images),
    ),
  },
}

export const Store = {
  state,
  getProduct: getById,
  getSimilar: (id: string, n: number) => {
    const available = pipe(
      catalogProducts,
      A.filter(product => product.id !== id && !product.reserved)
    )

    return randomNItems(n, available)
  },
}
