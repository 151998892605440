import { FC } from 'react'
import { Box, Divider, Typography, styled } from '@mui/material'
import { lightBlue } from '@mui/material/colors'
import { nonEmptyArray } from 'fp-ts'
import { Route } from 'common/Route'
import { useWebpSupported } from 'common/Webp'
import { Product } from 'lib/Store'
import { ImgData } from 'lib/types'
import * as css from 'lib/CSS'
import { AspectRatioBox } from './AspectRatioBox'
import { LazyImage } from './LazyImage'
import { LinkBase } from './Link'
import { Title } from './Title'

type Props = {
  product: Product
}

const TRIANGLE_WIDTH = 10
const TRIANGLE_HEIGHT = 8

const RootLink = styled(LinkBase)(({ theme }) => ({
  display: 'block',
  position: 'relative',
  background: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.common.black,
  },
}))

const SLazyImage = styled(LazyImage)(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  [theme.breakpoints.up('sm')]: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}))

const STitle = styled(Title)(({ theme }) => ({
  marginBottom: theme.spacing(0.25),
}))

const TextContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2, 4, 2.5)
  },
}))

const Badge = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  top: css.percentage(0.15),
  right: -TRIANGLE_WIDTH,
  background: lightBlue[100],
  padding: theme.spacing(0.75, 2),
  color: theme.palette.text.primary,
}))


const BadgeShadow = styled('span')({
  position: 'absolute',
  zIndex: 1,
  bottom: -TRIANGLE_HEIGHT,
  right: 0,
  width: 0,
  height: 0,
  borderTopWidth: TRIANGLE_HEIGHT,
  borderTopStyle: 'solid',
  borderTopColor: lightBlue[200],
  borderRightWidth: TRIANGLE_WIDTH,
  borderRightStyle: 'solid',
  borderRightColor: 'transparent',
})

export const ProductCard: FC<Props> = ({ product }) => {
  const img = nonEmptyArray.head(product.images)
  const webpSupported = useWebpSupported()

  return (
    <RootLink
      to={Route.product.path(product.id)}
    >
      <AspectRatioBox ratio={19/20}>
        <Box sx={{ p: { sm: 4 }, height: '100%' }}>
          <SLazyImage
            src={ImgData.selectSource({ img, webpSupported, thumb: true })}
            alt={img.alt}
          />
        </Box>
      </AspectRatioBox>

      {product.reserved && (
        <Badge>
          <Typography variant="button" color="inherit">
            Бронь
          </Typography>
          <BadgeShadow />
        </Badge>
      )}

      <Divider light />

      <TextContainer>
        <STitle
          component="h4"
          tone="light"
          fontSize={{ xs: 14 / 16, sm: 18 / 16 }}
          fontWeight={600}
          noWrap
        >
          {product.name.ru}
        </STitle>

        <Title
          component="h4"
          tone="light"
          fontSize={{ xs: 11 / 16, sm: 14 / 16 }}
          fontWeight={600}
          noWrap
        >
          {product.name.en}
        </Title>
      </TextContainer>
    </RootLink>
  )
}
