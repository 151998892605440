import { FC, memo } from 'react'
import { Container, Grid, styled } from '@mui/material'
import { Route } from 'common/Route'
import { metaData } from 'lib/MetaData'
import { Header, LazyImage, Link, Main, Meta, Section, Status, Title } from 'components'

const SGrid = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    textAlign: 'right',
  },
}))

const STitle = styled(Title)(({ theme }) => ({
  paddingBottom: theme.spacing(3/4),
  lineHeight: 1.2,
}))

const SLazyImage = styled(LazyImage)(({ theme }) => ({
  display: 'block',
  width: '100%',
  maxWidth: 200,
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    margin: 'initial',
  },
}))

export const NotFoundContainer: FC = memo(() => {
  return (
    <Status statusCode={404}>
      <Meta metaData={metaData.notFoundPage} />

      <Header />

      <Main>
        <Section>
          <Container maxWidth="lg" fixed>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={5}
            >
              <SGrid item xs={12} sm>
                <STitle component="h1" fontSize={1.75}>
                  Страница не найдена
                </STitle>

                <Link to={Route.home.path}>
                  Вернуться на главную
                </Link>
              </SGrid>

              <Grid item xs={12} sm>
                <SLazyImage
                  src="/assets/404.png"
                  alt="Страница не найдена"
                />
              </Grid>
            </Grid>
          </Container>
        </Section>
      </Main>
    </Status>
  )
})
