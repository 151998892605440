export type Contact = {
  label: string
  display: string
  href: string
  icon: string
}

const phone: Contact = {
  label: 'Телефон',
  display: '+375 (29) 287-47-87',
  href: 'tel:+375292874787',
  icon: '/assets/icons/phone.svg',
}

const email: Contact = {
  label: 'E-mail',
  display: 'likacvetokby@gmail.com',
  href: 'mailto:likacvetokby@gmail.com',
  icon: '/assets/icons/envelope.svg',
}

const viber: Contact = {
  label: 'Viber',
  display: '+375 (29) 287-47-87',
  href: 'viber://contact/?number=%2B375292874787',
  icon: '/assets/icons/viber.svg',
}

const whatsapp: Contact = {
  label: 'WhatsApp',
  display: '+375 (29) 287-47-87',
  href: 'https://wa.me/375292874787',
  icon: '/assets/icons/whatsapp.svg',
}

const telegram: Contact = {
  label: 'Telegram',
  display: '@likacvetok',
  href: 'https://t.me/likacvetok',
  icon: '/assets/icons/telegram.svg',
}

export const Contact = {
  list: [ phone, email, viber, whatsapp, telegram ],
  phone,
  email,
  viber,
  whatsapp,
  telegram,
}
