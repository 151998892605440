import { array, io, option, random } from 'fp-ts'
import { pipe } from 'fp-ts/function'

export const isNull = <A> (a: A | null | undefined): a is null | undefined =>
  a === undefined || a === null

export const randomItem = <A> (items: Array<A>): io.IO<option.Option<[A, Array<A>]>> =>
  array.isEmpty(items)
    ? io.of(option.none)
    : pipe(
        random.randomInt(0, items.length - 1),
        io.map(i => pipe(
          array.lookup(i, items),
          option.map(item => [item, array.unsafeDeleteAt(i, items)]),
        )),
      )

export const randomNItems = <A> (n: number, items: Array<A>): io.IO<option.Option<Array<A>>> =>
  n === 0
    ? io.of(option.some([]))
    : pipe(
        randomItem(items),
        io.chain(
          option.fold(
            () => io.of(option.none),
            ([a, as]) => pipe(
              randomNItems(n - 1, as),
              io.map(option.map(bs => array.cons(a, bs))),
            ),
          )
        )
      )

export const mkShouldForwardProps =
  <A>(as: Array<keyof A>) => (a: string) => !as.includes(a as keyof A)
