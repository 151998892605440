import React, { FC, useEffect, useMemo } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { JSS_SSR_STYLES_ID } from 'common/const'
import { task } from 'fp-ts'
import { constVoid, pipe } from 'fp-ts/function'
import { COOKIE_WEBP_SUPPORTED } from 'common/const'
import { WebpSupportedContext } from 'common/Webp'
import * as browser from 'lib/Browser'
import * as cookie from 'lib/Cookie'
import * as ioOption from 'lib/IOOption'
import { App } from 'App'
import { theme } from 'theme'

const removeSsrJss = pipe(
  browser.getElementById(JSS_SSR_STYLES_ID),
  ioOption.fold(
    () => constVoid,
    jssStyles => () => jssStyles.remove(),
  ),
)

const storeWebpSupported = (supported: boolean) => pipe(
  task.of(null),
  task.delay(0),
  task.chain(() => task.fromIO(cookie.setBoolean(COOKIE_WEBP_SUPPORTED, supported))),
)

export const BrowserApp: FC = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const webpSupported = useMemo(browser.webpSupported, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { storeWebpSupported(webpSupported)() }, [])
  useEffect(removeSsrJss, [])

  return (
    <BrowserRouter>
      <WebpSupportedContext.Provider value={webpSupported}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </WebpSupportedContext.Provider>
    </BrowserRouter>
  )
}
