import { FC } from 'react'
import { Box, Grid, styled } from '@mui/material'
import { LazyImage } from './LazyImage'
import { Paragraph } from './Paragraph'
import { Title } from './Title'

type Props = {
  title: string
  imageSrc: string
  imageAlt: string
}

const SLazyImage = styled(LazyImage)({
  width: 40,
  height: 32,
  objectFit: 'contain',
})


export const Benefit: FC<Props> = ({ children, title, imageSrc, imageAlt }) => {
  return (
    <Grid container spacing={3} wrap="nowrap">
      <Grid item>
        <SLazyImage src={imageSrc} alt={imageAlt} />
      </Grid>

      <Grid item>
        <Box pb={1}>
          <Title
            component="h3"
            tone="dark"
            fontSize={14/16}
            uppercase
          >
            {title}
          </Title>
        </Box>
        <Paragraph>
          {children}
        </Paragraph>
      </Grid>
    </Grid>
  )
}
