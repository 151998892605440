import { io, option } from 'fp-ts'
import { constVoid, pipe } from 'fp-ts/function'
import * as ioOption from './IOOption'

export const getElementById = (id: string): io.IO<option.Option<HTMLElement>> =>
  () => option.fromNullable(document.getElementById(id))

export const scrollToTop: io.IO<void> =
  () => window.scrollTo(0, 0)

export const scrollToAnchor = (anchor: string): io.IO<void> =>
  pipe(
    getElementById(anchor.startsWith('#') ? anchor.slice(1) : anchor),
    ioOption.fold(
      () => constVoid,
      el => () => el.scrollIntoView(),
    ),
  )

export const webpSupported: io.IO<boolean> =
  () => {
    const elem = document.createElement('canvas')

    return elem.getContext && elem.getContext('2d')
      ? elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
      : false
  }
