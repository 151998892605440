import { FC } from 'react'
import { Route } from 'react-router-dom'

type Props = {
  statusCode: number
}

export const Status: FC<Props> =
  ({ statusCode, children }) => (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.statusCode = statusCode
        }

        return children
      }}
    />
  )
