import { array, eq, nonEmptyArray, option } from 'fp-ts'
import { pipe } from 'fp-ts/function'
type Eq<A> = eq.Eq<A>
type NonEmptyArray<A> = nonEmptyArray.NonEmptyArray<A>

export type SelectArray <A> = {
  before: Array<A>
  value: A
  after: Array<A>
  length: number
}

export  const of = <A> (value: A): SelectArray<A> => ({
  before: array.empty,
  value,
  after: array.empty,
  length: 1,
})

export const fromNonEmptyArray = <A> (nea: NonEmptyArray<A>): SelectArray<A> => ({
  before: array.empty,
  value: nonEmptyArray.head(nea),
  after: nonEmptyArray.tail(nea),
  length: nea.length,
})

export const toArray = <A> (a: SelectArray<A>): NonEmptyArray<A> =>
  nonEmptyArray.concat(a.before, array.cons(a.value, a.after))

export const map = <A, B> (onUnselected: (e: A) => B, onSelected: (a: A) => B) => (sa: SelectArray<A>): SelectArray<B> => ({
  before: pipe(sa.before, array.map(onUnselected)),
  value: onSelected(sa.value),
  after: pipe(sa.after, array.map(onUnselected)),
  length: sa.length,
})

export const select = <A> (eq: Eq<A>) => (a: A, sa: SelectArray<A>): SelectArray<A> => {
  const nea = toArray(sa)

  return pipe(
    nea,
    array.findIndex(item => eq.equals(item, a)),
    option.map(index => {
      const [before, after] = pipe(nea, array.splitAt(index))

      return {
        before,
        value: a,
        after: pipe(array.tail(after), option.getOrElse<Array<A>>(() => array.empty)),
        length: sa.length,
      }
    }),
    option.getOrElse(() => sa),
  )
}

export const selectBefore = <A> (a: SelectArray<A>): SelectArray<A> =>
  pipe(
    nonEmptyArray.fromArray(a.before),
    option.map(neBefore => ({
      before: nonEmptyArray.init(neBefore),
      value: nonEmptyArray.last(neBefore),
      after: array.cons(a.value, a.after),
      length: a.length,
    })),
    option.alt(() => pipe(
      nonEmptyArray.fromArray(a.after),
      option.map((neAfter): SelectArray<A> => ({
        before: nonEmptyArray.init(nonEmptyArray.cons(a.value, neAfter)),
        value: nonEmptyArray.last(neAfter),
        after: array.empty,
        length: a.length,
      }))
    )),
    option.getOrElse(() => a),
  )

export const selectNext = <A> (a: SelectArray<A>): SelectArray<A> =>
  pipe(
    nonEmptyArray.fromArray(a.after),
    option.map(neAfter => ({
      before: array.snoc(a.before, a.value),
      value: nonEmptyArray.head(neAfter),
      after: nonEmptyArray.tail(neAfter),
      length: a.length,
    })),
    option.alt(() => pipe(
      nonEmptyArray.fromArray(a.before),
      option.map((neBefore): SelectArray<A> => ({
        before: array.empty,
        value: nonEmptyArray.head(neBefore),
        after: nonEmptyArray.tail(nonEmptyArray.snoc(neBefore, a.value)),
        length: a.length,
      }))
    )),
    option.getOrElse(() => a),
  )
