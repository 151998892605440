import { CSSProperties, FC, useState, useEffect } from 'react'
import { Skeleton, Theme, styled, useTheme } from '@mui/material'
import { toS3BucketUrl } from 'const'
import { option } from 'fp-ts'
import { constVoid, pipe } from 'fp-ts/function'
import { useViewport } from './Viewport'

type Props = {
  className?: string
  src: string
  alt: string
  skeletonStyles?: (theme: Theme) => CSSProperties
}

const Img = styled('img', {
  shouldForwardProp: prop => prop !== 'transparent',
})<{ transparent: boolean }>(({ theme, transparent }) => ({
  position: transparent ? 'absolute' : 'static',
  zIndex: transparent ? -1 : 1,
  opacity: transparent ? 0 : 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.easeOut,
  }),
}))

export const LazyImage: FC<Props> = props => {
  const theme = useTheme()
  const [visible, setVisible] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [skeletonRef, setSkeletonRef] = useState<HTMLElement | null>(null)
  const viewport = useViewport()
  useEffect(
    () =>
      pipe(
        option.fromNullable(skeletonRef),
        option.filter(el => el.getBoundingClientRect().top < viewport.size.height && !visible),
        option.fold(constVoid, () => {
          setVisible(true)
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    visible ? [null, null] : [viewport, skeletonRef],
  )
  useEffect(
    () => {
      if (loaded) setLoaded(false)
      if (visible) setVisible(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.src],
  )

  return (
    <>
      {!loaded && (
        <Skeleton
          ref={setSkeletonRef}
          style={props.skeletonStyles && props.skeletonStyles(theme)}
          variant="rectangular"
          animation="wave"
          width="100%"
          height="100%"
        />
      )}
      {visible && (
        <Img
          className={props.className}
          transparent={!loaded}
          src={toS3BucketUrl(props.src)}
          alt={props.alt}
          onLoad={() => setLoaded(true)}
        />
      )}
      <noscript><img className={props.className} src={props.src} alt={props.alt} /></noscript>
    </>
  )
}
