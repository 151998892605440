import * as t from 'io-ts'
import { flow, unsafeCoerce } from 'fp-ts/function'
import { toOriginUrl } from 'const'

interface RoutePathBrand {
  readonly RoutePath: unique symbol
}

export const RoutePath = t.brand(
  t.string,
  (_): _ is t.Branded<string, RoutePathBrand> => true,
  'RoutePath',
)

export type RoutePath =
  t.TypeOf<typeof RoutePath>

const pathFromString: (str: string) => RoutePath =
  unsafeCoerce

type StaticRoute = {
  path: RoutePath
  pathWithAnchor: (anchor: string) => RoutePath
  route: string
  url: string
}

const staticRoute = (path: string): StaticRoute => ({
  path: pathFromString(path),
  pathWithAnchor: anchor => pathFromString(`${path}#${anchor}`),
  route: path,
  url: toOriginUrl(path),
})

type ParameterizedRoute <P extends {}, K extends keyof P> = {
  path: (prop: P[K]) => RoutePath
  pathWithAnchor: (prop: P[K], anchor: string) => RoutePath
  route: string
  url: (prop: P[K]) => string
  urlWithAnchor: (prop: P[K], anchor: string) => string
}

const parameterizedRoute = <P extends {}> (prefix: string) =>
  <K extends keyof P> (key: K): ParameterizedRoute<P, K> => {
  const path: ParameterizedRoute<P, K>['path'] = prop => pathFromString(`${prefix}/${prop}`)
  const pathWithAnchor: ParameterizedRoute<P, K>['pathWithAnchor'] = (prop, anchor) => pathFromString(`${prefix}/${prop}#${anchor}`)

  return {
    path,
    pathWithAnchor,
    route: `${prefix}/:${key}`,
    url: flow(path, toOriginUrl),
    urlWithAnchor: flow(path, toOriginUrl),
  }
}

type ProductParams = {
  id: string
}

type UnknownParams <A extends Record<string, string>> = {
  [K in keyof A]?: string
}

export type MaybeProductParams = UnknownParams<ProductParams>

export const Route = {
  home: staticRoute('/'),
  catalog: staticRoute('/catalog'),
  contact: staticRoute('/contact'),
  gallery: staticRoute('/gallery'),
  product: parameterizedRoute<ProductParams>('/product')('id'),
}
