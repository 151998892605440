type Px =
    ((a: number) => string)
  & ((a: number, b: number) => string)
  & ((a: number, b: number, c: number) => string)
  & ((a: number, b: number, c: number, d: number) => string)

export const px: Px = (a: number, b?: number, c?: number, d?: number) => {
  if (b !== undefined) {
    if (c !== undefined) {
      if (d !== undefined) {
        return `${a}px ${b}px ${c}px ${d}px`
      } else {
        return `${a}px ${b}px ${c}px`
      }
    } else {
      return `${a}px ${b}px`
    }
  } else {
    return `${a}px`
  }
}

export const percentage =
  (value: number) => `${value * 100}%`

export const ms = (value: number): string =>
  `${value}ms`

export const rem = (value: number): string =>
  `${value}rem`

export const rgb = (r: number, g: number, b: number): string =>
  `rgba(${r}, ${g}, ${b})`

export const rgba = (r: number, g: number, b: number, a: number): string =>
  `rgba(${r}, ${g}, ${b}, ${a})`

export const scale = (value: number): string =>
  `scale(${value})`

export const url = (value: string) =>
  `url(${value})`

export const gray = {
  light: rgb(63, 63, 63),
  main: rgb(56, 56, 56),
  dark: rgb(48, 49, 51),
}
