import { COOKIE_TRUE, COOKIE_FALSE } from 'common/const'
import { io } from 'fp-ts'

export const setBoolean = (key: string, value: boolean): io.IO<void> =>
  () => {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 2)

    document.cookie = stringify([
      [key, value ? COOKIE_TRUE : COOKIE_FALSE],
      ['expires', date.toUTCString()],
      ['path', '/'],
    ])
  }

const stringify = (cookie: Array<[string, string]>): string =>
  cookie.map(([key, value]) => `${key}=${value}`).join('; ')
