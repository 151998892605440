import * as D from 'io-ts/Decoder'
import { nonEmptyArray, tuple } from 'fp-ts'

const SizeDecoder = D.union(
  D.tuple(D.number),
  D.tuple(D.number, D.number),
)

const display = (size: Size): string => {
  switch (size.length) {
    case 1:
      return `${nonEmptyArray.head(size)} см`

    case 2:
      return `От ${tuple.fst(size)} до ${tuple.snd(size)} см`
  }
}

export type Size = D.TypeOf<typeof SizeDecoder>

export const Size = {
  decoder: SizeDecoder,
  display,
}
