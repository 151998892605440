import { FC } from 'react'
import { styled } from '@mui/material'
import { mkShouldForwardProps } from 'lib/utils'

type Props = {
  ratio: number
}

const AspectRatioContainer = styled('div', {
  shouldForwardProp: mkShouldForwardProps<Props>(['ratio']),
})<Props>(({ ratio }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: `${1 / ratio * 100}%`,
}))

const AspectRatioBody = styled('div')({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
})

export const AspectRatioBox: FC<Props> =
  props => (
    <AspectRatioContainer ratio={props.ratio}>
      <AspectRatioBody>
        {props.children}
      </AspectRatioBody>
    </AspectRatioContainer>
  )
