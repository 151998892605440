import { FC } from 'react'
import { Route as RouterRoute, Switch } from 'react-router-dom'
import { CssBaseline, NoSsr } from '@mui/material'
import { Route } from 'common/Route'
import { Footer, ScrollHandler, ViewportProvider } from 'components'
import { CatalogContainer, ContactContainer, GalleryContainer, HomeContainer, NotFoundContainer, ProductContainer } from 'containers'
import { NonEmptyGalleryGuard, ProductGuard } from 'guards'

export const App: FC =
  () => (
    <ViewportProvider>
      <CssBaseline />

      <NoSsr>
        <ScrollHandler />
      </NoSsr>

      <Switch>
        <RouterRoute path={Route.home.route} exact>
          <HomeContainer />
        </RouterRoute>

        <RouterRoute path={Route.catalog.route}>
          <CatalogContainer />
        </RouterRoute>

        <RouterRoute path={Route.contact.route}>
          <ContactContainer />
        </RouterRoute>

        <RouterRoute path={Route.gallery.route}>
          <NonEmptyGalleryGuard>
            {galleryImages => <GalleryContainer galleryImages={galleryImages} />}
          </NonEmptyGalleryGuard>
        </RouterRoute>

        <RouterRoute path={Route.product.route}>
          <ProductGuard>
            {product => <ProductContainer product={product} />}
          </ProductGuard>
        </RouterRoute>

        <RouterRoute>
          <NotFoundContainer />
        </RouterRoute>
      </Switch>

      <Footer />
    </ViewportProvider>
  )
