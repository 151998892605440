import { FC, forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link as MuiLink, LinkProps as MuiLinkProps, alpha, styled } from '@mui/material'
import { RoutePath } from 'common/Route'
import { mkShouldForwardProps } from 'lib/utils'

type LinkBaseProps = {
  className?: string
  to: RoutePath
  exact?: boolean
}

type LinkProps = LinkBaseProps & {
  color?: 'inherit'

  align?: MuiLinkProps['align']
  display?: MuiLinkProps['display']
  underline?: MuiLinkProps['underline']
  variant?: MuiLinkProps['variant']
  onClick?: MuiLinkProps['onClick']
}

export const LinkBase =
  forwardRef<HTMLAnchorElement, LinkBaseProps>((props, ref) => (
    <RouterLink ref={ref} {...props} />
  ))

const MuiLinkExtended: FC<LinkProps> =
  props => <MuiLink component={LinkBase} {...props} />

export const Link = styled(MuiLinkExtended, {
  shouldForwardProp: mkShouldForwardProps(['color']),
})<{ color?: 'inherit' }>(({ theme, color }) => ({
  color: color || theme.palette.link.main,
  textDecorationColor: color || alpha(theme.palette.link.main, 0.4),
}))
