import { FC } from 'react'
import { Typography, TypographyProps, styled } from '@mui/material'
import * as css from 'lib/CSS'

type Props = {
  className?: string
  align?: TypographyProps['align']
  noWrap?: boolean
}

const STypography = styled(Typography)({
  lineHeight: 1.7,
  fontSize: css.rem(15/16),
})

export const Paragraph: FC<Props> =
  props => (
    <STypography
      color="textSecondary"
      variant="body2"
      {...props}
    />
  )
