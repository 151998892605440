import { FC, MouseEventHandler } from 'react'
import { Box, ButtonBase, Grid, styled } from '@mui/material'
import { pipe } from 'fp-ts/function'
import { useWebpSupported } from 'common/Webp'
import * as SA from 'lib/SelectArray'
import { ImgData } from 'lib/types'
import { mkShouldForwardProps } from 'lib/utils'
import { AspectRatioBox } from './AspectRatioBox'
import { HoverableImage } from './HoverableImage'
import { LazyImage } from './LazyImage'

type Props = {
  data: SA.SelectArray<ImgData>
  onCurrentImgChange: (img: ImgData) => void
  onFullscreen: MouseEventHandler
}

const ThumbButton = styled(ButtonBase, {
  shouldForwardProp: mkShouldForwardProps(['active']),
})<{ active?: boolean }>(({ active }) => ({
  width: '100%',
  opacity: active ? 1 : 0.6,
}))

const SLazyImage = styled(LazyImage)({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
})

export const Slider: FC<Props> = props => {
  const webpSupported = useWebpSupported()

  return (
    <>
      <HoverableImage onClick={props.onFullscreen}>
        <AspectRatioBox ratio={1}>
          <SLazyImage
            src={ImgData.selectSource({ img: props.data.value, webpSupported, thumb: false })}
            alt={props.data.value.alt}
          />
        </AspectRatioBox>
      </HoverableImage>

      {props.data.length > 1 && (
        <Box mt={2}>
          <Grid container spacing={1} alignItems="stretch">
            {pipe(
              props.data,
              SA.map(
                img => (
                  <Grid key={img.origin.src.jpg} item xs={3}>
                    <ThumbButton disableRipple onClick={() => props.onCurrentImgChange(img)}>
                      <AspectRatioBox ratio={1}>
                        <SLazyImage
                          src={ImgData.selectSource({ img, webpSupported, thumb: true })}
                          alt={props.data.value.alt}
                        />
                      </AspectRatioBox>
                    </ThumbButton>
                  </Grid>
                ),
                selectedImg => (
                  <Grid key={selectedImg.origin.src.jpg} item xs={3}>
                    <ThumbButton disableRipple active>
                      <AspectRatioBox ratio={1}>
                        <SLazyImage
                          src={ImgData.selectSource({ img: selectedImg, webpSupported, thumb: true })}
                          alt={props.data.value.alt}
                        />
                      </AspectRatioBox>
                    </ThumbButton>
                  </Grid>
                ),
              ),
              SA.toArray,
            )}
          </Grid>
        </Box>
      )}
    </>
  )
}
