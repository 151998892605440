import { FC, memo } from 'react'
import { Container, Grid } from '@mui/material'
import { metaData } from 'lib/MetaData'
import { Store } from 'lib/Store'
import { CallUsFab, Header, Main, Meta, Section, SectionHeader, Title, ProductCard } from 'components'
import { infoJson } from 'lib/JsonData'

export const CatalogContainer: FC =
  memo(() => (
    <>
      <Meta metaData={metaData.catalogPage} />

      <Header />

      <Main>
        <CallUsFab />

        <Section dark>
          <Container maxWidth="lg" fixed>
            <SectionHeader>
              <Title component="h3" align="center" fontSize={{ xs: 1.5, md: 1.875, lg: 2.125 }} uppercase>
                Каталог на {infoJson.displayYear}
              </Title>
            </SectionHeader>

            <Grid container spacing={{ xs: 2, sm: 3 }}>
              {Store.state.products.catalogList.map(product => (
                <Grid key={product.id} item xs={6} md={4} lg={3}>
                  <ProductCard product={product} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Section>
      </Main>
    </>
  ))
