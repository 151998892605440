import { FC, memo, useState } from 'react'
import { Box, Container, styled } from '@mui/material'
import { nonEmptyArray } from 'fp-ts'
import { useWebpSupported } from 'common/Webp'
import * as css from 'lib/CSS'
import { metaData } from 'lib/MetaData'
import { Store } from 'lib/Store'
import * as SA from 'lib/SelectArray'
import { ImgData } from 'lib/types'
import { AspectRatioBox, CallUsFab, FullscreenSlider, Header, HoverableImage, LazyImage, Main, Meta, Section, SectionHeader, Title } from 'components'

type NonEmptyArray<A> = nonEmptyArray.NonEmptyArray<A>

type Props = {
  galleryImages: NonEmptyArray<ImgData>
}

const SLazyImage = styled(LazyImage)({
  width: '100%',
  height: '100%',
  objectFit: 'cover'
})

export const GalleryContainer: FC<Props> = memo(props => {
  const [fullscreenSliderOpen, setFullscreenSliderOpen] = useState(false)
  const [sliderData, setSliderData] = useState(SA.fromNonEmptyArray(props.galleryImages))
  const webpSupported = useWebpSupported()

  return (
    <>
      <Meta metaData={metaData.galleryPage} />

      <Header />

      <FullscreenSlider
        open={fullscreenSliderOpen}
        data={sliderData}
        onCurrentImgChange={img => setSliderData(SA.select(ImgData.eq)(img, sliderData))}
        onBefore={() => setSliderData(SA.selectBefore(sliderData))}
        onNext={() => setSliderData(SA.selectNext(sliderData))}
        onClose={() => setFullscreenSliderOpen(false)}
      />

      <Main>
        <CallUsFab />

        <Section dark>
          <Container maxWidth="lg" fixed>
            <SectionHeader>
              <Title component="h1" align="center" fontSize={{ xs: 1.75, md: 2, lg: 2.5 }}>
                Фотогалерея
              </Title>
            </SectionHeader>
            <Box sx={{ p: -1, display: 'flex', flexWrap: 'wrap' }}>
              {Store.state.gallery.byYear.map(item =>
                item.images.map(img => (
                  <Box
                    key={img.thumb.src.jpg}
                    sx={{ p: 0.25, flexBasis: { xs: css.percentage(1/3), md: css.percentage(1/4) } }}
                  >
                    <HoverableImage
                      onClick={() => {
                        setSliderData(SA.select(ImgData.eq)(img, sliderData))
                        setFullscreenSliderOpen(true)
                      }}
                    >
                      <AspectRatioBox ratio={1}>
                        <SLazyImage
                          src={ImgData.selectSource({ img, webpSupported, thumb: true })}
                          alt={img.alt}
                        />
                      </AspectRatioBox>
                    </HoverableImage>
                  </Box>
                ))
              )}
            </Box>
          </Container>
        </Section>
      </Main>
    </>
  )
})
