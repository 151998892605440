import { FC } from 'react'
import { alpha, Box, Grid, Link, styled } from '@mui/material'
import { Contact as ContactT } from 'lib/Contact'
import { LazyImage } from './LazyImage'
import { Title } from './Title'

type Props = {
  contact: ContactT
}

const Image = styled(LazyImage)({
  width: 32,
  height: 32,
  objectFit: 'contain',
})

const SLink = styled(Link)(({ theme }) => ({
  color: theme.palette.link.main,
  textDecorationColor: alpha(theme.palette.link.main, 0.4),
}))

export const Contact: FC<Props> = ({ contact }) => (
  <Grid container alignItems="center" spacing={3} wrap="nowrap">
    <Grid item>
      <Image src={contact.icon} alt={contact.label} />
    </Grid>
    <Grid item>
      <Box mb={0.5}>
        <Title component="h5" tone="dark" fontSize={15/16}>
          {contact.label}
        </Title>
      </Box>
      <SLink
        variant="body1"
        href={contact.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {contact.display}
      </SLink>
    </Grid>
  </Grid>
)
