import * as D from 'io-ts/Decoder'
import { record as R, string } from 'fp-ts'
import { pipe } from 'fp-ts/function'
import { Size } from './Size'
import { TulipGroup } from './TulipGroup'
import { ResponsiveImage, NameWithExtension } from './types'

export const ProductName = D.struct({
  ru: D.string,
  en: D.string
})

export type ProductName = D.TypeOf<typeof ProductName>

const ProductBase = D.struct({
  name: ProductName,
  reserved: D.boolean,
  group: TulipGroup.decoder,
  description: D.array(D.string),
  attrs: D.partial({
    color: D.string,
    flowerHeight: Size.decoder,
    budHeight: Size.decoder,
    budDiameter: Size.decoder,
  }),
})

export const UnoptimizedProduct = pipe(
  ProductBase,
  D.intersect(D.struct({ image: NameWithExtension })),
  D.intersect(D.partial({ images: D.array(NameWithExtension) })),
  D.map(product => ({ ...product, images: product.images ?? [] }))
)

export type UnoptimizedProduct = D.TypeOf<typeof UnoptimizedProduct>

export const OptimizedProduct = pipe(
  ProductBase,
  D.intersect(D.struct({
    image: ResponsiveImage.decoder,
    images: D.array(ResponsiveImage.decoder)
  }))
)

export type OptimizedProduct = D.TypeOf<typeof OptimizedProduct>

export const ProductJson = D.record(OptimizedProduct)

export type ProductJson = D.TypeOf<typeof ProductJson>

export interface Product extends OptimizedProduct {
  id: string
  name: {
    ru: string
    en: string
    full: string
  }
}

export const productListFromJson = (json: ProductJson): Array<Product> => pipe(
  json,
  R.collect(string.Ord)((id, product) => {
    return {
      ...product,
      id,
      name: {
        ru: product.name.ru,
        en: product.name.en,
        full: `Тюльпан ${product.name.ru} (${product.name.en})`,
      }
    }
  })
)
