import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { option } from 'fp-ts'
import { pipe } from 'fp-ts/function'
import { MetaData } from 'lib/MetaData'

type Props = {
  metaData: MetaData
}

export const Meta: FC<Props> = ({ metaData }) => (
  <Helmet>
    <title>{metaData.title}</title>
    <meta name="title" content={metaData.title} />
    <meta name="description" content={metaData.description} />
    <meta name="keywords" content={metaData.keywords} />

    {pipe(
      metaData.social,
      option.map(({ url, imageSrc, imageAlt }) => [
        <link key="canonical" rel="canonical" href={url} />,

        <meta key="og:type"        property="og:type"        content="website" />,
        <meta key="og:url"         property="og:url"         content={url} />,
        <meta key="og:locale"      property="og:locale"      content="ru_RU" />,
        <meta key="og:title"       property="og:title"       content={metaData.title}/>,
        <meta key="og:description" property="og:description" content={metaData.description} />,
        <meta key="og:image"       property="og:image"       content={imageSrc} />,
        <meta key="og:image:alt"   property="og:image:alt"   content={imageAlt} />,

        <meta key="twitter:card"        name="twitter:card"        content="summary_large_image" />,
        <meta key="twitter:url"         name="twitter:url"         content={url} />,
        <meta key="twitter:domain"      name="twitter:domain"      content="likacvetok.by" />,
        <meta key="twitter:title"       name="twitter:title"       content={metaData.title} />,
        <meta key="twitter:description" name="twitter:description" content={metaData.description} />,
        <meta key="twitter:image"       name="twitter:image"       content={imageSrc}/>,
      ]),
      option.toUndefined,
    )}
  </Helmet>
)
