import React, { FC, ReactElement } from 'react'
import { option, nonEmptyArray } from 'fp-ts'
import { pipe } from 'fp-ts/function'
import { Store } from 'lib/Store'
import { ImgData } from 'lib/types'
import { NotFoundContainer } from 'containers'
type NonEmptyArray<A> = nonEmptyArray.NonEmptyArray<A>

type Props = {
  children: (images: NonEmptyArray<ImgData>) => ReactElement
}

export const NonEmptyGalleryGuard: FC<Props> = ({ children: f }) => {
  return pipe(
    nonEmptyArray.fromArray(Store.state.gallery.images),
    option.fold(
      () => <NotFoundContainer />,
      f,
    ),
  )
}
