import { FC, createContext, useContext, useMemo } from 'react'
import { useWindowScroll, useWindowSize } from 'react-use'

const ViewportContext = createContext(
  { scroll: { x: 0, y: 0 }, size: { width: 0, height: 0 } },
)

export const useViewport = () => useContext(ViewportContext)

export const ViewportProvider: FC =
  props => {
    const scroll = useWindowScroll()
    const size = useWindowSize()
    const viewport = useMemo(() => ({ scroll, size }), [scroll, size])

    return (
      <ViewportContext.Provider value={viewport}>
        {props.children}
      </ViewportContext.Provider>
    )
  }
