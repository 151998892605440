import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserApp } from './BrowserApp'
import * as serviceWorker from './serviceWorker'

(process.env.NODE_ENV === 'development' ? ReactDOM.render : ReactDOM.hydrate)(
  <React.StrictMode>
    <BrowserApp />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
