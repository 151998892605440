import { styled } from '@mui/material'
import * as css from 'lib/CSS'
import { mkShouldForwardProps } from 'lib/utils'

export const Section = styled('section', {
  shouldForwardProp: mkShouldForwardProps(['dark', 'dense']),
})<{ dense?: boolean, dark?: boolean }>(({ theme, dark, dense }) => ({
  backgroundColor: dark ? css.rgb(241, 241, 241) : undefined,
  padding: theme.spacing(4, 0),
  [theme.breakpoints.up('sm')]: {
    padding: dense ? undefined : theme.spacing(5, 0),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(dense ? 5 : 6, 0),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(dense ? 6 : 8, 0),
  },
}))

export const SectionHeader = styled('header', {
  shouldForwardProp: mkShouldForwardProps(['dense']),
})<{ dense?: boolean }>(({ theme, dense }) => ({
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    paddingBottom: dense ? undefined : theme.spacing(5),
  },
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(dense ? 5 : 6),
  },
  [theme.breakpoints.up('lg')]: {
    paddingBottom: theme.spacing(dense ? 6 : 8),
  },
}))
