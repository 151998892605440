import { FC, KeyboardEventHandler } from 'react'
import { ButtonBase, Dialog, dialogClasses, alpha, styled } from '@mui/material'
import { boolean, io, option } from 'fp-ts'
import { constVoid, pipe } from 'fp-ts/function'
import { useWebpSupported } from 'common/Webp'
import * as SA from 'lib/SelectArray'
import { ImgData } from 'lib/types'
import { mkShouldForwardProps } from 'lib/utils'
import { LazyImage } from './LazyImage'

import ClearIcon from '@mui/icons-material/Clear'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const UI_BLOCK_SIZE = 80
const UI_BLOCK_SIZE_SM = 120

type Props = {
  open: boolean
  data: SA.SelectArray<ImgData>
  onCurrentImgChange: (img: ImgData) => void
  onBefore: () => void
  onNext: () => void
  onClose: () => void
}

const SDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    backgroundColor: alpha(theme.palette.common.black, 0.6),
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const ControlsRoot = styled('div')({
  position: 'absolute',
  zIndex: 1,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
})

const ControlsHeader = styled('div')(({ theme }) => ({
  height: UI_BLOCK_SIZE,
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('sm')]: {
    height: UI_BLOCK_SIZE_SM,
  },
}))

const ControlsBody = styled('div')({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'space-between',
})

const ControlsButton = styled(ButtonBase)(({ theme }) => ({
  height: '100%',
  width: UI_BLOCK_SIZE,
  color: theme.palette.grey[500],
  transition: theme.transitions.create(['color', 'background'], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.standard,
  }),
  '&:hover': {
    color: theme.palette.common.white,
    background: alpha(theme.palette.common.black, 0.3),
  },
  [theme.breakpoints.up('sm')]: {
    width: UI_BLOCK_SIZE_SM,
  },
}))

const Carousel = styled('ol')(({ theme }) => ({
  height: UI_BLOCK_SIZE,
  margin: 0,
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    height: UI_BLOCK_SIZE_SM,
  },
}))

const CarouselItem = styled('li', {
  shouldForwardProp: mkShouldForwardProps(['active']),
})<{ active?: boolean }>(({ theme, active }) => ({
  background: theme.palette.common.white,
  width: theme.spacing(1.5),
  height: theme.spacing(1.5),
  margin: theme.spacing(0.5),
  listStyle: 'none',
  opacity: active ? 1 : 0.5,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.shorter,
  }),
  borderRadius: '50%',
  cursor: 'pointer',
}))

const SLazyImage = styled(LazyImage)({
  maxHeight: '100%',
  maxWidth: '100%',
  objectFit: 'contain',
})

export const FullscreenSlider: FC<Props> = props => {
  const webpSupported = useWebpSupported()
  const handleKeyDown: KeyboardEventHandler = event => pipe(
    props.open,
    boolean.fold(
      constVoid,
      pipe(
        event.key,
        key => {
          switch (key) {
            case 'ArrowLeft':
              return option.some(props.onBefore)
            case 'ArrowRight':
              return option.some(props.onNext)
            case 'Escape':
              return option.some(props.onClose)
            default:
              return option.none
          }
        },
        option.map(io.chain(() => () => event.stopPropagation())),
        option.getOrElse(() => constVoid),
      ),
    ),
  )

  return (
    <SDialog
      open={props.open}
      fullScreen
      onClose={props.onClose}
      onKeyDown={handleKeyDown}
    >
      <SLazyImage
        skeletonStyles={theme => ({ backgroundColor: theme.palette.grey[800] })}
        src={ImgData.selectSource({ img: props.data.value, webpSupported, thumb: false })}
        alt={props.data.value.alt}
      />

      <ControlsRoot>
        <ControlsHeader>
          <ControlsButton onClick={props.onClose} disableRipple>
            <ClearIcon fontSize="large" />
          </ControlsButton>
        </ControlsHeader>

        {props.data.length > 1 && (
          <ControlsBody>
            <ControlsButton onClick={props.onBefore} disableRipple>
              <NavigateBeforeIcon fontSize="large" />
            </ControlsButton>

            <ControlsButton onClick={props.onNext} disableRipple>
              <NavigateNextIcon fontSize="large" />
            </ControlsButton>
          </ControlsBody>
        )}

        {props.data.length > 1 && props.data.length <= 10 && (
          <Carousel>
            {pipe(
              props.data,
              SA.map(
                img => (
                  <CarouselItem
                    key={img.thumb.src.webp}
                    onClick={() => props.onCurrentImgChange(img)}
                  />
                ),
                selectedImg => (
                  <CarouselItem key={selectedImg.thumb.src.webp} active />
                )
              ),
              SA.toArray,
            )}
          </Carousel>
        )}
      </ControlsRoot>
    </SDialog>
  )
}
