import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { boolean } from 'fp-ts'
import { pipe } from 'fp-ts/function'
import * as browser from 'lib/Browser'

export const ScrollHandler: FC = () => {
  const location = useLocation()

  useEffect(() => pipe(
    location.hash !== '',
    boolean.fold(browser.scrollToTop, browser.scrollToAnchor(location.hash)),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [location.key])

  return null
}
