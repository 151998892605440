import { FC, MouseEventHandler } from 'react'
import { ButtonBase, alpha, styled } from '@mui/material'
import * as css from 'lib/CSS'

import FullscreenIcon from '@mui/icons-material/Fullscreen'

type Props = {
  className?: string
  onClick: MouseEventHandler
}

const Root = styled('div')({
  position: 'relative',
})

const SButtonBase = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  zIndex: 1,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: alpha(theme.palette.common.black, 0.3),
  transition: theme.transitions.create(['opacity', 'transform'], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.shorter,
  }),
  opacity: 0,
  '&:hover': {
    opacity: 1,
  },
  '& > svg': {
    color: theme.palette.common.white,
    fontSize: css.rem(3),
    transform: css.scale(1.5),
    transition: 'inherit',
  },
  '&:hover > svg': {
    transform: css.scale(1),
  },
}))

export const HoverableImage: FC<Props> =
  props => (
    <Root className={props.className}>
      <SButtonBase onClick={props.onClick}>
        <FullscreenIcon  />
      </SButtonBase>
      {props.children}
    </Root>
  )
