import { FC, memo } from 'react'
import { Container, Typography, alpha, styled } from '@mui/material'
import { Route } from 'common/Route'
import * as css from 'lib/CSS'
import { Link } from './Link'

const Root = styled('footer')(({ theme }) => ({
  backgroundColor: css.rgb(231, 231, 231),
  padding: theme.spacing(3, 0),
}))

const SLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecorationColor: alpha(theme.palette.common.black, 0.4),
}))

const copyright = '\u00A9'

const getYear = () => new Date().getFullYear()

export const Footer: FC = memo(() => (
  <Root>
    <Container maxWidth="lg" fixed>
      <Typography color="textSecondary" variant="body2">
        {`${copyright} ${getYear()} `}
        <SLink to={Route.home.path} underline="hover">
          likacvetok.by
        </SLink>
        {'. Все права защищены.'}
      </Typography>
    </Container>
  </Root>
))
