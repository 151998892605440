import React, { FC, ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { option } from 'fp-ts'
import { pipe } from 'fp-ts/function'
import { MaybeProductParams } from 'common/Route'
import { Product, Store } from 'lib/Store'
import { NotFoundContainer } from 'containers'

type Props = {
  children: (product: Product) => ReactElement
}

export const ProductGuard: FC<Props> = ({ children: f }) => {
  const params = useParams<MaybeProductParams>()

  return pipe(
    option.fromNullable(params.id),
    option.chain(Store.getProduct),
    option.fold(
      () => <NotFoundContainer />,
      f,
    ),
  )
}
