import { createTheme } from '@mui/material'
import { blue, pink, lightBlue } from '@mui/material/colors'

export const titleFont = 'Montserrat, serif'
export const bodyFont = 'Source Sans Pro, sans-serif'

export const theme = createTheme({
  palette: {
    primary: {
      main: pink[300],
    },
    secondary: {
      main: lightBlue[100],
    },
    link: {
      main: blue[700]
    },
  },
  typography: {
    fontFamily: bodyFont,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '#root': {
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
  },
})

declare module '@mui/material/styles' {
  interface Palette {
    link: Palette['primary'];
  }
  interface PaletteOptions {
    link: PaletteOptions['primary'];
  }
}
