import { FC } from 'react'
import { Box, Container, styled } from '@mui/material'
import { toS3BucketUrl } from 'const'
import * as css from 'lib/CSS'
import { isNull, mkShouldForwardProps } from 'lib/utils'
import { Title } from './Title'

type Props = {
  title: string
  subtitle?: string
  imageSrc: string
}

const Root = styled('div', {
  shouldForwardProp: mkShouldForwardProps(['url']),
})<{ url: string }>(({ url }) => ({
  backgroundImage: `url(${toS3BucketUrl(url)})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
}))

const Foreground = styled('div')({
  backgroundColor: css.rgba(255, 255, 255, 0.5),
})

const Text = styled('div', {
  shouldForwardProp: mkShouldForwardProps(['align']),
})<{ align: 'center' | 'initial' }>(({ theme, align }) => ({
  padding: theme.spacing(10, 0),
  fontSize: css.rem(28/16),
  lineHeight: 1,
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(12, 0),
    fontSize: css.rem(2.5),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
    fontSize: css.rem(3),
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: css.rem(3.5),
    padding: theme.spacing(20, 0),
    textAlign: align,
  },
}))

export const Banner: FC<Props> =
  props => (
    <Root url={props.imageSrc}>
      <Foreground>
        {props.children}
        <Container maxWidth="lg" fixed>
          <Text align={isNull(props.subtitle) ? 'center' : 'initial'}>
            <Title
              component="h1"
              tone="light"
              fontSize="inherit"
              uppercase
            >
              {props.title}
            </Title>

            {!isNull(props.subtitle) && (
              <Box mt={3}>
                <Title
                  component="h2"
                  tone="light"
                  fontSize="inherit"
                  fontWeight={500}
                >
                  {props.subtitle}
                </Title>
              </Box>
            )}
          </Text>
        </Container>
      </Foreground>
    </Root>
  )
